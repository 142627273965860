* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background-color: #121212;
  color: #f5f5f5;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

a {
  color: #007bff;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

/* Base input styles */
input, select, textarea {
  font-family: inherit;
  font-size: inherit;
}

/* Focus styles */
:focus {
  outline-color: #007bff;
}

/* Dark scrollbar for webkit browsers */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #1e1e1e;
}

::-webkit-scrollbar-thumb {
  background: #444;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Placeholder styling */
::placeholder {
  color: #aaa;
  opacity: 0.7;
}