/* Color Variables */
:root {
  --primary-bg: #121212;
  --secondary-bg: #1e1e1e;
  --card-bg: #2d2d2d;
  --primary-text: #f5f5f5;
  --secondary-text: #aaaaaa;
  --accent-color: #007bff;
  --accent-hover: #0056b3;
  --error-color: #cf6679;
  --success-color: #4caf50;
  --border-color: #333333;
  --shadow-color: rgba(0, 0, 0, 0.5);
}

body {
  background-color: var(--primary-bg);
  color: var(--primary-text);
  margin: 0;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  color: var(--primary-text);
}

/* Navigation styles */
.navigation {
  background-color: var(--secondary-bg);
  color: var(--primary-text);
  padding: 12px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 4px var(--shadow-color);
}

.nav-brand {
  display: flex;
  align-items: center;
}

.nav-brand h3 {
  margin: 0;
  margin-left: 15px;
}

.nav-actions {
  display: flex;
  align-items: center;
  gap: 20px;
}

.nav-tabs {
  display: flex;
  gap: 2px;
}

.nav-tab {
  background: none;
  border: none;
  color: var(--secondary-text);
  padding: 8px 16px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  border-radius: 4px;
  transition: all 0.2s;
}

.nav-tab:hover {
  background-color: rgba(255, 255, 255, 0.05);
  color: var(--primary-text);
}

.nav-tab.active {
  background-color: rgba(0, 123, 255, 0.1);
  color: var(--accent-color);
}

.menu-button, .login-button, .logout-button {
  background: none;
  border: none;
  color: var(--primary-text);
  cursor: pointer;
  padding: 8px 15px;
  font-size: 14px;
  border-radius: 4px;
  transition: background-color 0.2s;
}

.menu-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.login-button {
  background-color: var(--accent-color);
}

.login-button:hover {
  background-color: var(--accent-hover);
}

.logout-button {
  background-color: var(--error-color);
  margin-left: 10px;
}

.logout-button:hover {
  opacity: 0.9;
}

.user-email {
  font-size: 14px;
  color: var(--secondary-text);
}

/* App container */
.app-container {
  display: flex;
  height: calc(100vh - 56px);
  position: relative;
  overflow: hidden;
}

/* Side panel */
.side-panel {
  width: 300px;
  height: 100%;
  background-color: var(--secondary-bg);
  box-shadow: -2px 0 10px var(--shadow-color);
  position: absolute;
  top: 0;
  right: 0;
  transition: transform 0.3s ease;
  z-index: 1000;
  overflow-y: auto;
}

.side-panel.closed {
  transform: translateX(300px);
}

.side-panel.open {
  transform: translateX(0);
}

#panel-content {
  padding: 20px;
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  color: var(--secondary-text);
  padding: 5px 10px;
  border-radius: 4px;
}

.close-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

/* Pull tab button */
.pullTab {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  z-index: 999;
  background-color: var(--secondary-bg);
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
  box-shadow: 0 2px 5px var(--shadow-color);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--accent-color);
}

.pullTab:hover {
  background-color: var(--card-bg);
}

/* Content area */
.content-area {
  flex: 1;
  margin-right: 50px;
  height: 100%;
  overflow-y: auto;
  background-color: var(--primary-bg);
}

/* Removed split-view layout - no longer needed */

/* Map container */
.map-container {
  position: relative;
  height: 100%;
  width: 100%;
}

/* Simple marker styles - no animations or complex styling */
.fire-marker {
  /* Empty rule set - use default Leaflet styling */
}

/* Floating buttons */
.floating-buttons {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 900;
  display: flex;
  flex-direction: column;
  gap: 10px;
  transition: right 0.3s ease;
}

.side-panel.open ~ .content-area .floating-buttons {
  right: 320px;
}

.layers-button, .maps-button {
  background-color: var(--secondary-bg);
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
  box-shadow: 0 2px 5px var(--shadow-color);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--accent-color);
}

.layers-button:hover, .maps-button:hover {
  background-color: var(--card-bg);
}

/* Panel content */
.panel-content {
  color: var(--primary-text);
}

.panel-content h3 {
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 10px;
  margin-top: 0;
}

.panel-content input[type="text"],
.panel-content input[type="email"],
.panel-content input[type="password"],
.panel-content input[type="number"],
.panel-content input[type="url"] {
  background-color: var(--card-bg);
  border: 1px solid var(--border-color);
  color: var(--primary-text);
  padding: 10px;
  border-radius: 4px;
  width: 100%;
  margin-bottom: 15px;
}

.panel-content button {
  background-color: var(--accent-color);
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 10px;
  margin-bottom: 15px;
  transition: background-color 0.2s;
}

.panel-content button:hover {
  background-color: var(--accent-hover);
}

.panel-content button:disabled {
  background-color: #555;
  cursor: not-allowed;
}

/* Side menu styles */
.side-menu {
  color: var(--primary-text);
  height: 100%;
  display: flex;
  flex-direction: column;
}

.tabs-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.tabs {
  display: flex;
  border-bottom: 1px solid var(--border-color);
  margin-bottom: 15px;
  background-color: var(--secondary-bg);
  position: sticky;
  top: 0;
  z-index: 10;
}

.tab {
  padding: 12px 15px;
  background: none;
  border: none;
  border-bottom: 3px solid transparent;
  color: var(--secondary-text);
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.2s ease;
  flex: 1;
  text-align: center;
}

.tab:hover {
  color: var(--primary-text);
  background-color: rgba(255, 255, 255, 0.05);
}

.tab.active {
  color: var(--accent-color);
  border-bottom-color: var(--accent-color);
  background-color: rgba(0, 123, 255, 0.05);
}

.tab-content {
  padding: 0 10px;
  flex: 1;
  overflow-y: auto;
}

.menu-section {
  margin-bottom: 25px;
}

.menu-section h4 {
  margin-bottom: 15px;
  color: var(--secondary-text);
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.menu-section ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu-section li {
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.2s;
}

.layer-options label {
  display: block;
  margin-bottom: 10px;
  padding: 8px;
  border-radius: 4px;
  transition: background-color 0.2s;
  cursor: pointer;
}

.layer-options label:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

/* Form elements */
.location-form input,
.scanner-form input,
.filter-form input[type="text"],
.filter-form input[type="number"],
.filter-form select {
  background-color: var(--card-bg);
  border: 1px solid var(--border-color);
  color: var(--primary-text);
  padding: 10px;
  border-radius: 4px;
  width: 100%;
  margin-bottom: 10px;
}

.scanner-form label,
.filter-form label {
  display: block;
  margin-top: 10px;
  margin-bottom: 5px;
  font-size: 0.9em;
  color: var(--secondary-text);
}

.scanner-form input[type="url"],
.scanner-form input[type="number"] {
  margin-bottom: 15px;
}

.filter-form input[type="checkbox"] {
  margin-right: 8px;
}

.filter-form label {
  margin-bottom: 12px;
}

.button-group {
  display: flex;
  gap: 10px;
  margin-top: 15px;
}

.primary-button, 
.location-form button {
  background-color: var(--accent-color);
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.primary-button:hover,
.location-form button:hover {
  background-color: var(--accent-hover);
}

.secondary-button {
  background-color: transparent;
  color: var(--primary-text);
  border: 1px solid var(--border-color);
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s;
}

.secondary-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.status-box {
  background-color: var(--card-bg);
  border-radius: 4px;
  padding: 15px;
  margin-top: 10px;
}

.status-box p {
  margin: 8px 0;
  font-size: 0.9em;
}

.target-list {
  background-color: var(--card-bg);
  border-radius: 4px;
  padding: 15px;
  height: 150px;
  overflow-y: auto;
}

/* Status info */
.status-info {
  background-color: var(--card-bg);
  padding: 15px;
  border-radius: 4px;
  margin-top: 20px;
}

.status-info h4 {
  margin-top: 0;
  margin-bottom: 15px;
  color: var(--primary-text);
}

.status-info p {
  margin: 8px 0;
  color: var(--secondary-text);
}

/* Error and loading states */
.error-message {
  color: var(--error-color);
  margin-bottom: 15px;
  padding: 10px;
  background-color: rgba(207, 102, 121, 0.1);
  border-radius: 4px;
}

.loading {
  text-align: center;
  margin: 20px 0;
  color: var(--secondary-text);
}

/* Targets and notifications lists */
.targets-container {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.targets-list, .notifications-list {
  flex: 1;
  min-width: 0;
}

.targets-list ul, .notifications-list ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.targets-list li, .notifications-list li {
  padding: 10px;
  margin-bottom: 8px;
  background-color: var(--card-bg);
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.targets-list li:hover, .notifications-list li:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.targets-list li.selected, .notifications-list li.selected {
  background-color: var(--accent-color);
  color: white;
}

.notification-details {
  background-color: var(--card-bg);
  padding: 15px;
  border-radius: 4px;
  margin-top: 20px;
}

.detail-item {
  margin-bottom: 10px;
}

.fires-list {
  margin-top: 20px;
}

.fire-item {
  background-color: rgba(76, 175, 80, 0.1);
  padding: 15px;
  border-radius: 4px;
  margin-bottom: 15px;
}

/* Icons */
.icon {
  width: 20px;
  height: 20px;
  color: var(--primary-text);
}

/* Link buttons */
.link-button {
  background: none;
  border: none;
  color: var(--accent-color);
  text-decoration: underline;
  cursor: pointer;
  font-size: 0.9rem;
  padding: 0;
  margin: 0;
}

.link-button:hover {
  color: var(--accent-hover);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .side-panel {
    width: 100%;
    max-width: 100%;
  }
  
  .content-area {
    margin-left: 0;
  }
  
  .targets-container {
    flex-direction: column;
  }
  
  .targets-list, .notifications-list {
    width: 100%;
  }
}