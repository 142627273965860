/* Leaflet Dark Theme Overrides */
.leaflet-container {
  background-color: #1a1a1a !important;
}

.leaflet-control-attribution {
  background-color: rgba(30, 30, 30, 0.8) !important;
  color: #aaa !important;
}

.leaflet-control-attribution a {
  color: #4dabf7 !important;
}

.leaflet-control-zoom {
  background-color: #2d2d2d !important;
  border: 1px solid #444 !important;
}

.leaflet-control-zoom a {
  color: #f5f5f5 !important;
  background-color: #2d2d2d !important;
}

.leaflet-control-zoom-in, .leaflet-control-zoom-out {
  border-bottom: 1px solid #444 !important;
}

.leaflet-control-layers {
  background-color: #2d2d2d !important;
  color: #f5f5f5 !important;
  border: 1px solid #444 !important;
}

.leaflet-control-layers-toggle {
  background-color: #2d2d2d !important;
}

.leaflet-control-layers-expanded {
  background-color: #2d2d2d !important;
  color: #f5f5f5 !important;
}

.leaflet-popup-content-wrapper, .leaflet-popup-tip {
  background-color: #2d2d2d !important;
  color: #f5f5f5 !important;
  box-shadow: 0 3px 14px rgba(0, 0, 0, 0.7) !important;
}

/* Add these specific Leaflet layer control styles to ensure they're visible in dark mode */
.leaflet-control-layers-selector {
  margin-right: 5px !important;
}

.leaflet-control-layers label {
  display: flex !important;
  align-items: center !important;
  margin-bottom: 5px !important;
  color: #f5f5f5 !important;
}

.leaflet-control-layers-selector + span {
  margin-left: 5px !important;
}

/* Form styling */
input[type="radio"], input[type="checkbox"] {
  accent-color: #007bff;
}

/* Error and validation states */
.validation-error {
  border-color: #cf6679 !important;
}

.validation-success {
  border-color: #4caf50 !important;
}

/* Console-like output styling for logs/status */
.console-output {
  background-color: #121212;
  border: 1px solid #333;
  color: #0f0;
  font-family: monospace;
  padding: 10px;
  border-radius: 4px;
  margin-top: 15px;
  height: 150px;
  overflow-y: auto;
}

.console-line {
  margin: 2px 0;
}

.console-time {
  color: #999;
  margin-right: 8px;
}

.console-info {
  color: #4dabf7;
}

.console-warning {
  color: #ffc107;
}

.console-error {
  color: #cf6679;
}

/* Additional button styles */
.btn-primary {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 15px;
  border-radius: 4px;
  cursor: pointer;
}

.btn-danger {
  background-color: #cf6679;
  color: white;
  border: none;
  padding: 8px 15px;
  border-radius: 4px;
  cursor: pointer;
}

.btn-success {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 8px 15px;
  border-radius: 4px;
  cursor: pointer;
}

/* Additional card styling */
.card {
  background-color: #2d2d2d;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  padding: 15px;
  margin-bottom: 20px;
}

.card-header {
  border-bottom: 1px solid #444;
  padding-bottom: 10px;
  margin-bottom: 15px;
  font-weight: bold;
}

.card-content {
  padding-bottom: 10px;
}

.card-footer {
  border-top: 1px solid #444;
  padding-top: 10px;
  margin-top: 15px;
  display: flex;
  justify-content: flex-end;
}

/* Notification Center Styles */
.notification-center {
  padding: 16px;
  max-width: 400px;
  background-color: #2d2d2d;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  color: #f5f5f5;
}

.notification-center h3 {
  margin-top: 0;
  color: #f5f5f5;
  border-bottom: 1px solid #444;
  padding-bottom: 10px;
  margin-bottom: 16px;
}

.notification-center h4 {
  margin: 16px 0 8px;
  color: #e0e0e0;
}

.notification-center h5 {
  margin: 12px 0 8px;
  color: #d0d0d0;
}

.fire-info-section {
  background-color: #252525;
  padding: 12px;
  border-radius: 6px;
  margin-bottom: 16px;
}

.notification-section {
  margin-bottom: 16px;
}

.whatsapp-section, .everbridge-section {
  padding: 12px;
  border: 1px solid #444;
  border-radius: 6px;
  margin-bottom: 12px;
}

.whatsapp-section textarea {
  width: 100%;
  margin: 8px 0;
  padding: 8px;
  border: 1px solid #555;
  border-radius: 4px;
  resize: vertical;
  background-color: #333;
  color: #f5f5f5;
}

.notification-center button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  margin-top: 8px;
}

.notification-center .close-button {
  background-color: #444;
  color: #f5f5f5;
  float: right;
  padding: 5px 10px;
  margin-top: 0;
}

.whatsapp-button {
  background-color: #25D366;
  color: white;
  width: 100%;
}

.everbridge-button {
  background-color: #3B5998;
  color: white;
  width: 100%;
}

.error-message {
  color: #cf6679;
  padding: 8px;
  background-color: rgba(207, 102, 121, 0.2);
  border-radius: 4px;
  margin-top: 16px;
}

.success-message {
  color: #4caf50;
  padding: 8px;
  background-color: rgba(76, 175, 80, 0.2);
  border-radius: 4px;
  margin-top: 16px;
}